import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SocialIcon } from 'react-social-icons';

const InstagramWidget = ({ username = 'kamilwysockifotografia' }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    axios
      .get(`https://www.instagram.com/${username}/?__a=1`)
      .then((userData) => {
        if (userData !== user) {
          setUser(userData.data.graphql.user);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user.profile_pic_url) {
    return null;
  }

  return (
    <a
      href="https://www.instagram.com/kamilwysockifotografia/"
      target="__blank"
    >
      <div className="instagram-widget">
        <div className="instagram-widget-avatar">
          <img src={user.profile_pic_url} alt="Avatar" />
        </div>
        <div className="instagram-widget-info">
          <h1 class="instagram-widget-username">kamilwysockifotografia</h1>
          <p class="instagram-widget-metric">
            obserwujących: <strong>{user.edge_followed_by.count}</strong>
          </p>
          <p class="instagram-widget-metric">
            kliknij, aby przejść na
            <SocialIcon
              style={{ height: 24, width: 24 }}
              network="instagram"
              fgColor="#333"
              bgColor="transparent"
            ></SocialIcon>
          </p>
        </div>
      </div>
    </a>
  );
};

export default InstagramWidget;
