import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import InstagramWidget from '../components/common/InstagramWidget';

const Kontakt = ({ data, location, pageContext }) => {
  let email = `poczta@kamilwysocki.pl`;
  return (
    <>
      <MetaData location={location} />
      <Layout>
        <div className="contact-container">
          <div className="contact-image">
          </div>
          <div className="contact-content">
            <h2>Kontakt</h2>
            <p>Chcesz umówić się na sesję? Napisz do mnie na mój adres: <a href="mailto:poczta@kamilwysocki.pl"><strong>{email}</strong></a><br />Możemy również skontaktować się przez Instagram <a href="https://www.instagram.com/kamilwysockifotografia/" target="_blank"><strong>@kamilwysockifotografia.</strong></a></p>
            <InstagramWidget></InstagramWidget>
          </div>
        </div>
      </Layout>
    </>
  );
};

Kontakt.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default Kontakt;
